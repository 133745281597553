<template>
  <div class="pagination-container">
    <el-pagination
      :class="{}"
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: "ZPagination",

  inject: ['Frame'],

  props: {
    total: {
      required: true,
      type: Number
    },

    page: {
      type: Number,
      default: 0
    },

    limit: {
      type: Number,
      default: 10
    },

    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50];
      }
    },

    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper"
    },

    background: {
      type: Boolean,
      default: true
    },

    autoScroll: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page + 1;
      },
      set(val) {
        this.$emit("update:page", val - 1);
      }
    },

    pageSize: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit("update:limit", val);
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("pagination", { page: this.currentPage, limit: val });
      if (this.autoScroll) {
        this.Frame.scrollToTop();
      }
    },

    handleCurrentChange(val) {
      this.$emit("pagination", { page: val, limit: this.pageSize });
      if (this.autoScroll) {
        this.Frame.scrollToTop();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination-container {
  background: #fff;
  padding: 20px;
  text-align: right;

  /deep/ .is-background {
    .btn-prev,
    .el-pager li,
    .btn-next {
      line-height: 26px;
      background-color: #fff;
      border-top: 1px solid #dcdfe6;
      border-bottom: 1px solid #dcdfe6;
      border-left: 1px solid #dcdfe6;
      margin: 0;
      border-radius: 0;
    }

    .btn-prev {
      border-radius: 5px 0 0 5px;
    }

    .btn-next {
      border-right: 1px solid #dcdfe6;
      border-radius: 0 5px 5px 0;
    }

    .el-pager li.active {
      background-color: $--color-primary;
      border-color: $--color-primary;
      color: #fff;
    }
  }
}
</style>
