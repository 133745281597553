<template>
    <z-frame
		ref="frame"
		:title="projectName"
		:auto-router="autoRouter"
		:menus="menus"
		:roles="roles"
		:config="{logo: false, menuPosition: 'right'}"
		@role-change="init"
		@menu-click="handlerMenuClick"
		@logout="logout"
    >
        <router-view></router-view>
    </z-frame>
</template>

<script>
    import { removeToken } from "@/assets/js/auth";
    export default {
        data() {
            return {
                menus: [],
                roles: [],
                autoRouter: true,
                projectName: ""
            };
        },

        mounted() {
            const role = [
                { label: "管理员", value: 0 },
                { label: "评委", value: 1 },
                { label: "学校用户", value: 2 },
                { label: "活动管理员", value: 3 }];

            const path = this.$route.path;

            if( path.indexOf('/Administrator') !== -1 ) {
                if (sessionStorage.getItem("roleType") == 'K'){
                    this.roles = [role[3], role[2]]
                }else {
                    this.roles = [role[0]]
                }

            }

            if( path.indexOf('/Judge') !== -1 ) {
                this.roles = [role[1]]
            }

            if( path.indexOf('/School') !== -1 ) {
                if (sessionStorage.getItem("roleType") == 'K'){
                    this.roles = [role[3], role[2]]
                }else {
                    this.roles = [role[2]]
                }
            }

            this.$post('/queryProjectName').then((m) => {
                this.projectName = m.content.projectName;
            });

        },

        methods: {
            init(role, done) {
                // this.$post('/').then(rep => {

                switch (role) {
                    case 0:
                        this.menus = [
                            { path: "/Administrator/Appraise", name: "评选活动管理" },
                            { path: "/Administrator/User", name: "用户管理" },
                            { path: "/Administrator/System", name: "系统设置" },
                            { path: "/Administrator/AuthInfoList", name: "权限管理" }
                        ];
                        break;
                    case 1:
                        this.menus = [{ path: "/Judge/WorksList", name: "作品列表" }];
                        break;
                    case 2:
                        this.menus = [
                            { path: "/School/SubmitWorks", name: "作品上报" },
                            { path: "/School/PersonalDetail", name: "个人信息" }
                        ];
                        break;
                    case 3:
                        this.menus = [
                            { path: "/Administrator/Appraise", name: "评选活动管理" },
                            { path: "/Administrator/System", name: "系统设置" }
                        ];
                        break;
                }

                if(role == 2) { //学校用户查询手机号是否填写
                    this.$post('/querySchoolTelphone').then((m) => {
                        if(m.resultCode == -1) {
                            this.autoRouter = false;
//	      			this.$refs.frame.selectMenu("/School/PersonalDetail")
                            this.$router.replace({path: '/School/PersonalDetail'})
                            this.$message({
                                showClose: true,
                                type: 'warning',
                                message: '您的个人信息不完善，请先完善个人信息！'
                            });
                        }
                    });
                }
                // })
                done();
            },
            logout() {
                sessionStorage.clear();
                removeToken();
                this.$router.push({ path: '/Login' })
            },
            handlerMenuClick(item, index) {
                this.$router.push({path: item.path})
            },
        }
    };
</script>

<style lang="scss" scoped>
</style>
