import Vue from 'vue'
import { dateFormat, numberComma } from '../js/utils'

Vue.filter('YYYY', function(val) {
  return dateFormat(val, 'YYYY');
})

Vue.filter('YYYYMM', function(val) {
  return dateFormat(val, 'YYYY-MM');
})

Vue.filter('YMD', function(val) {
  return dateFormat(val, 'YYYY-MM-DD');
})

Vue.filter('YMDHm', function(val) {
  return dateFormat(val, 'YYYY-MM-DD HH:mm');
})

Vue.filter('YMDHms', function(val) {
  return dateFormat(val, 'YYYY-MM-DD HH:mm:ss');
})

Vue.filter('Hm', function(val) {
  return dateFormat(val, 'HH:mm');
})

Vue.filter('numberComma', function(val) {
	return numberComma(val);
})
