const TOKEN = 'ACCESS_TOKEN',
  APP_TOKEN = 'APP_ACCESS_TOKEN';

export const getToken = function (type = 'pc') {
  return type === 'app' ? sessionStorage.getItem(APP_TOKEN) : sessionStorage.getItem(TOKEN);
};

export const setToken = function (value, type = 'pc') {
  return type === 'app' ? sessionStorage.setItem(APP_TOKEN, value) : sessionStorage.setItem(TOKEN, value);
};

export const removeToken = function () {
  sessionStorage.removeItem(TOKEN);
  sessionStorage.removeItem(APP_TOKEN);
};
