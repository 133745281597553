<template>
  <section class="z-frame">
    <header
      :class="['z-frame__header', 'clearfix', {
      'is-full': getConfig.headerFull || $route.meta.headerFull
    }]"
    >
      <div class="z-frame__logo-title pull-left">
        <img src="./img/logo.png" class="z-frame__logo" v-if="getConfig.logo" />
        <h3 class="z-frame__title">{{title}}</h3>
      </div>

      <!-- 全局返回按钮 -->
      <section class="z-frame__back pull-right" v-if="needBack">
        <img src="./img/icon-back.png" alt class="icon-back" @click="$router.go(-1)" />
      </section>

      <!-- 角色 退出 -->
  <section class="pull-right" v-if="!needBack">
    <!--   <el-dropdown class="z-frame__role-select" trigger="click">
     <span class="el-dropdown-link">
       <img src="./img/avatar.png" alt class="img" />

       <span v-if="roles.length">{{roles[0].label}}</span>

       <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
     </span>

     <el-dropdown-menu slot="dropdown">
       <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
     </el-dropdown-menu>
   </el-dropdown>-->
         <div
          :class="['z-frame__role-select', {
          'is-single': roles.length === 1
        }]"
          v-if="getConfig.role || roles.length"
        >
          <img src="./img/avatar.png" alt class="img" />
          <el-select v-model="roleSelect" v-if="roles.length > 1" @change="handlerRoleChange">
            <el-option
              v-for="(item, index) in roles"
              :key="'users-item-' + index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <span v-if="roles.length == 1" style="color: #fff;">{{roles[0].label}}</span>
        </div>

         <div class="z-frame__logout" v-if="getConfig.logout">
          <img src="./img/logout.png" alt class="img" @click="logout" />
        </div>
      </section>

      <!-- 菜单 -->
      <ul
        :class="['z-frame__menu-list', 'clearfix', {
        'pull-right': getConfig.menuPosition === 'right' ? true : false
      }]"
        v-if="getConfig.menu && !needBack"
      >
        <li
          :id="'menu-item-' + index"
          class="z-frame__menu-item"
          :class="{ 'router-link-active' : isActive === index }"
          v-for="(item, index) in menus"
          :key="'menu-item-' + index"
          @click="handlerMenuClick(item, index)">
          {{item.name}}
        </li>
      </ul>
    </header>

    <el-scrollbar
      id="z-frame__main-container"
      :class="[ 'z-frame__main-container', { 'back-gauge' : !!$route.meta.bodyFull || !getConfig.bodyFull }]"
    >
      <slot></slot>
    </el-scrollbar>
  </section>
</template>

<script>
/**
 * @param {title} String 项目名称
 * @param {menus} Array 菜单列表，[{path: '/Home', label: '首页'}]
 * @param {roles} Array 角色列表，[{label: '教师', value: 1}]
 * @param {config} Boolean 配置对象  详见CONFIG
 * @event {menu-click} 菜单点击事件，默认选中传入菜单的第一项，返回当前点击的对象及index
 * @event {role-change} 角色切换事件，默认触发选中第一项，返回当前选中的值
 */
import { merge } from "../../js/utils";
import router from "@/router";

let STORE = {};
router.beforeEach((to, from, next) => {
  STORE = to;
  next();
});

const CONFIG = {
  logo: true,
  menu: true,
  role: true,
  logout: true,
  headerFull: false,
  bodyFull: false,
  menuPosition: "left"
};
export default {
  name: "ZFrame",

  data() {
    return {
      isActive: 0,
      roleSelect: [],
      loading: null,
      isPackup: false,
      needBack: false
    };
  },

  provide() {
    return {
      Frame: this
    };
  },

  props: {
    title: [String],
    menus: [Array],
    roles: [Array],
    config: {
      default: () => ({})
    },
    dontResetList: {
      //初始进入不刷新的页面名
      type: Array,
      default: () => []
    },
    autoRouter: {
    	type: Boolean,
    	default: true
    }
  },

  computed: {
    getConfig() {
      return merge(this.config, CONFIG);
    }
  },

  watch: {
    roles: {
      immediate: true,
      handler(value) {
        this.handlerRoleSelect();
      }
    },
    // menus(val) {
    //   this.handlerMenuSelect();
    // },
    //监听 跳转链接恢复滚动条到顶部
    $route(to, from) {
      this.scrollToTop();
      this.needBack = !!to.meta.needBack;
    }
  },

  mounted: function() {
    this.$nextTick(function() {});
  },

  methods: {
    scrollToTop() {
      this.$nextTick(() => {
        document.querySelector(
          "#z-frame__main-container .el-scrollbar__wrap"
        ).scrollTop = 0;
      });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const main = document.querySelector(
          "#z-frame__main-container .el-scrollbar__wrap"
        );
        main.scrollTop = main.querySelector(".el-scrollbar__view").clientHeight;
      });
    },

    handlerMenuSelect() {
      if (!this.menus.length) return;
      this.$nextTick(() => {
        this.$router.replace({path: this.menus[0].path});
      })
    },

    handlerMenuClick(item, index) {
      console.log(item)
      this.isActive = index;
      this.$emit('menu-click', item, index)
    },

    handlerRoleSelect() {
      if (!this.roles.length) return;

      this.roleSelect = this.roles[0].value; //默认选中第一个角色
      //判断初始化进入是否有不需要重置的链接
      // let drl = this.dontResetList.find(d => d === STORE.name);
      // if (!!drl) {
      //   this.loading.close();
      // } else {
      //   this.handlerRoleChange(this.roleSelect);
      // }
      this.handlerRoleChange(this.roleSelect);
    },

    isLoading() {
      if (!this.loading || !this.loading.visible) {
        this.loading = this.$loading({
          lock: true,
          text: "加载中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      }
    },

    handlerRoleChange(value) {
      //角色切换
      this.isLoading();
      this.isActive = 0;
      this.$emit("role-change", value, this.handleRoleDone);
    },

    handleRoleDone() {
      this.$nextTick(() => {
        this.closeLoading();
        this.handlerMenuSelect();
      });
    },

    closeLoading() {
      this.loading.close();
    },

    logout() {
      this.$emit("logout");
    }
  }
};
</script>

<style lang="scss" scope>
@import "./scss/styles.scss";
</style>
