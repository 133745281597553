import Vue from 'vue'
import Router from 'vue-router'
import Entry from '@/components/Entry'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '*',
      redirect: '/Login'
    },
    {
      path: '/Login',
      name: 'Login',
      component: () => import('@/components/Login')
    },
    {
      path: '/OauthLogin',
      name: 'OauthLogin',
      component: () => import('@/components/OauthLogin')
    },
    { //管理员
      path: '/Administrator',
      component: Entry,
      redirect: '/Administrator/Appraise',
      children: [{ //评分活动管理
        path: 'Appraise',
        name: 'AppraiseAdministration',
        component: () => import('@/components/administrator/Appraise')
      }, { //评分进度
        path: 'Appraise/Progress',
        name: 'AppraiseProgress',
        meta: {
          needBack: true
        },
        component: () => import('@/components/administrator/Progress')
      }, { //查看评委帐户
        path: 'Appraise/CheckAccount',
        name: 'AppraiseCheckAccount',
        meta: {
          needBack: true
        },
        component: () => import('@/components/administrator/CheckAccount')
      }, { //评选分组管理
        path: 'Appraise/Group',
        name: 'AppraiseGroup',
        meta: {
          needBack: true
        },
        component: () => import('@/components/administrator/Group')
      }, { //增加分组
        path: 'Appraise/AddGroup',
        name: 'AppraiseAddGroup',
        meta: {
          needBack: true
        },
        component: () => import('@/components/administrator/AddGroup')
      },  { //增加分组2
        path: 'Appraise/AddAutoGroup',
        name: 'AppraiseAddAutoGroup',
        meta: {
          needBack: true
        },
        component: () => import('@/components/administrator/AddAutoGroup')
      }, { //汇总
        path: 'Appraise/Summarize',
        name: 'AppraiseSummarize',
        meta: {
          needBack: true
        },
        component: () => import('@/components/administrator/Summarize')
      }, { //汇总明细
        path: 'Appraise/SummarizeDetail',
        name: 'AppraiseSummarizeDetail',
        meta: {
          needBack: true
        },
        component: () => import('@/components/administrator/SummarizeDetail')
      }, { //查看上报作品
        path: 'Appraise/CheckSubmitWorks',
        name: 'AppraiseCheckSubmitWorks',
        meta: {
          needBack: true
        },
        component: () => import('@/components/administrator/CheckSubmitWorks')
      }, { //查看未评作品
        path: 'Appraise/UnPingJiaVideo',
        name: 'AppraiseUnPingJiaVideo',
        meta: {
          needBack: true
        },
        component: () => import('@/components/administrator/UnPingJiaVideo')
      }, { //推荐库
        path: 'Appraise/Recommend',
        name: 'AppraiseRecommend',
        meta: {
          needBack: true
        },
        component: () => import('@/components/administrator/Recommend')
      }, { //用户管理
        path: 'User',
        name: 'AppraiseUser',
        component: () => import('@/components/administrator/User')
      }, { //系统设置
        path: 'System',
        name: 'AppraiseSystem',
        component: () => import('@/components/administrator/System')
      },{ //权限设置
        path: 'AuthInfoList',
        name: 'AppraiseAuthInfoList',
        component: () => import('@/components/administrator/AuthInfoList').then(m => m.default)
      }]
    },
    { //评委
      path: '/Judge',
      component: Entry,
      redirect: '/Judge/WorksList',
      children: [{ //作品列表
        path: 'WorksList',
        name: 'WorksList',
        component: () => import('@/components/Judge/WorksList')
      }, { //作品列表
        path: 'WorksDetail',
        name: 'WorksDetail',
        meta: {
          needBack: true
        },
        component: () => import('@/components/Judge/Detail')
      }]
    },
    //学校
    {
      path: '/School',
      component: Entry,
      redirect: '/School/SubmitWorks',
      children: [{ //上报作品
        path: 'SubmitWorks',
        name: 'SubmitWorks',
        component: () => import('@/components/School/SubmitWorks')
      }, { //作品管理
        path: 'SubmitWorks/ManagementWorks',
        name: 'ManagementWorks',
        meta: {
          needBack: true
        },
        component: () => import('@/components/School/ManagementWorks')
      }, { //个人信息
        path: 'PersonalDetail',
        name: 'PersonalDetail',
        component: () => import('@/components/School/PersonalDetail')
      }]
    },
    {
      path: '/ShowFile',
      name: 'ShowFile',
      component: () => import('@/components/showFile').then(m => m.default)
    },
  ]
})
