<template>
  <div class="z-plate">
    <section
      :class="['z-plate__title', 'clearfix', {
      'has-bottom-border': hasBottomBorder
    }]"
    >
      <div class="left pull-left">
        <span v-if="title">{{title}}</span>

        <slot name="titleLeft" v-if="$slots.titleLeft"></slot>
      </div>

      <div class="right pull-right">
        <slot name="titleRight" v-if="$slots.titleRight"></slot>
      </div>
    </section>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ZPlate",

  data() {
    return {};
  },

  props: {
    hasBottomBorder: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.z-plate {
  padding-bottom: 15px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  .z-plate__title {
    line-height: 36px;
    padding: 20px;

    &.has-bottom-border {
      border-bottom: 1px solid #ebeef5;
    }

    /deep/ .el-tabs {
      margin: -20px;
      line-height: 78px;

      .el-tabs__header {
        margin: 0;

        .el-tabs__nav-wrap {
          padding: 0 20px;

          &::after {
            display: none;
          }
        }
      }
    }

    .left {
      font-size: 18px;
      color: #666;
    }
  }
}
</style>