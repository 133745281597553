/* istanbul ignore next */

import Vue from 'vue';

const isServer = Vue.prototype.$isServer;
const SPECIAL_CHARS_REGEXP = /([\:\-\_]+(.))/g;
const MOZ_HACK_REGEXP = /^moz([A-Z])/;
const ieVersion = isServer ? 0 : Number(document.documentMode);

const empty = function () {};

/* istanbul ignore next */
const trim = function (string) {
  return (string || '').replace(/^[\s\uFEFF]+|[\s\uFEFF]+$/g, '');
};

/* istanbul ignore next */
const camelCase = function (name) {
  return name.replace(SPECIAL_CHARS_REGEXP, function (_, separator, letter, offset) {
    return offset ? letter.toUpperCase() : letter;
  }).replace(MOZ_HACK_REGEXP, 'Moz$1');
};

/* istanbul ignore next */
export const on = (function () {
  if (!isServer && document.addEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false);
      }
    };
  } else {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.attachEvent('on' + event, handler);
      }
    };
  }
})();

/* istanbul ignore next */
export const off = (function () {
  if (!isServer && document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false);
      }
    };
  } else {
    return function (element, event, handler) {
      if (element && event) {
        element.detachEvent('on' + event, handler);
      }
    };
  }
})();

/* istanbul ignore next */
export const once = function (el, event, fn) {
  var listener = function () {
    if (fn) {
      fn.apply(this, arguments);
    }
    off(el, event, listener);
  };
  on(el, event, listener);
};

/* istanbul ignore next */
export function hasClass(el, cls) {
  if (!el || !cls) return false;
  if (cls.indexOf(' ') !== -1) throw new Error('className should not contain space.');
  if (el.classList) {
    return el.classList.contains(cls);
  } else {
    return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1;
  }
};

/* istanbul ignore next */
export function addClass(el, cls) {
  if (!el) return;
  var curClass = el.className;
  var classes = (cls || '').split(' ');

  for (var i = 0, j = classes.length; i < j; i++) {
    var clsName = classes[i];
    if (!clsName) continue;

    if (el.classList) {
      el.classList.add(clsName);
    } else if (!hasClass(el, clsName)) {
      curClass += ' ' + clsName;
    }
  }
  if (!el.classList) {
    el.className = curClass;
  }
};

/* istanbul ignore next */
export function removeClass(el, cls) {
  if (!el || !cls) return;
  var classes = cls.split(' ');
  var curClass = ' ' + el.className + ' ';

  for (var i = 0, j = classes.length; i < j; i++) {
    var clsName = classes[i];
    if (!clsName) continue;

    if (el.classList) {
      el.classList.remove(clsName);
    } else if (hasClass(el, clsName)) {
      curClass = curClass.replace(' ' + clsName + ' ', ' ');
    }
  }
  if (!el.classList) {
    el.className = trim(curClass);
  }
};

export function siblings(element, name) {
  if (!name) {
    return element.parentNode.childNodes
  } else {
    let childNodes = []
    element.parentNode.childNodes.forEach((child) => {
      if (child.className.indexOf(name) != -1) {
        childNodes.push(child)
      }
    })
    return childNodes
  }
}

/* istanbul ignore next */
export const getStyle = ieVersion < 9 ? function (element, styleName) {
  if (isServer) return;
  if (!element || !styleName) return null;
  styleName = camelCase(styleName);
  if (styleName === 'float') {
    styleName = 'styleFloat';
  }
  try {
    switch (styleName) {
      case 'opacity':
        try {
          return element.filters.item('alpha').opacity / 100;
        } catch (e) {
          return 1.0;
        }
        default:
          return (element.style[styleName] || element.currentStyle ? element.currentStyle[styleName] : null);
    }
  } catch (e) {
    return element.style[styleName];
  }
} : function (element, styleName) {
  if (isServer) return;
  if (!element || !styleName) return null;
  styleName = camelCase(styleName);
  if (styleName === 'float') {
    styleName = 'cssFloat';
  }
  try {
    var computed = document.defaultView.getComputedStyle(element, '');
    return element.style[styleName] || computed ? computed[styleName] : null;
  } catch (e) {
    return element.style[styleName];
  }
};

/* istanbul ignore next */
export function setStyle(element, styleName, value) {
  if (!element || !styleName) return;

  if (typeof styleName === 'object') {
    for (var prop in styleName) {
      if (styleName.hasOwnProperty(prop)) {
        setStyle(element, prop, styleName[prop]);
      }
    }
  } else {
    styleName = camelCase(styleName);
    if (styleName === 'opacity' && ieVersion < 9) {
      element.style.filter = isNaN(value) ? '' : 'alpha(opacity=' + value * 100 + ')';
    } else {
      element.style[styleName] = value;
    }
  }
};

/**
 * 获取窗口宽高对象
 */
export function winsize() {
  let winWidth = 0;
  let winHeight = 0;

  //获取窗口宽度
  if (window.innerWidth) {
    winWidth = window.innerWidth;
  } else if ((document.body) && (document.body.clientWidth)) {
    winWidth = document.body.clientWidth;
  }

  //获取窗口高度
  if (window.innerHeight) {
    winHeight = window.innerHeight;
  } else if ((document.body) && (document.body.clientHeight)) {
    winHeight = document.body.clientHeight;
  }

  //通过深入Document内部对body进行检测，获取窗口大小
  if (document.documentElement && document.documentElement.clientHeight && document.documentElement.clientWidth) {
    winHeight = document.documentElement.clientHeight;
    winWidth = document.documentElement.clientWidth;
  }

  return {
    width: winWidth,
    height: winHeight
  };
}

export function getEmt(selector) {
  return document.querySelector(selector);
}

/*
 * 合并参数
 */
export function merge(userOptions, options) {
  Object.keys(userOptions).forEach(function (key) {
    options[key] = userOptions[key];
  });

  return options;
}

export const findIndex = (function () {
  if (Array.prototype.findIndex) {
    return function (array, predicate) {
      return array.findIndex(predicate)
    }
  } else {
    return function (array, predicate) {
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }
      let o = array;
      let len = o.length >>> 0;
      let thisArg = arguments[1];

      let k = 0;

      while (k < len) {
        let kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return k;
        }
        k++;
      }

      return -1;
    }
  }
})();

/**
 * 判定是否为移动端
 */
export const isMobile = function () {
  const sUserAgent = navigator.userAgent.toLowerCase();
  const winW = window.screen.width;
  if ((sUserAgent.match(/android/i) ||
      sUserAgent.match(/webos/i) ||
      sUserAgent.match(/iphone/i) ||
      sUserAgent.match(/ipod/i) ||
      sUserAgent.match(/ipad/i) ||
      sUserAgent.match(/blackberry/i) ||
      sUserAgent.match(/windows phone/i) ||
      sUserAgent.match(/windows ce/i) ||
      sUserAgent.match(/windows mobile/i) ||
      sUserAgent.match(/midp/i))) {
    return true
  } else {
    return false
  }
}

/**
 * 设置移动端font-size
 * @param {arg} Number 设计图尺寸
 */
export function setHtmlFontSizeOfRem(arg) {
  document.body.addEventListener('touchstart', empty)

  let style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = "html, body{ height:100%; -webkit-overflow-scrolling: touch; }";
  document.querySelector('head').appendChild(style);

  function setSize() {
    const remSize = winsize().width / arg;
    document.querySelector("html").style.fontSize = (remSize > 100 ? 100 : remSize) + "px";
  }
  setSize();
  on(window, 'resize', () => {
    setTimeout(() => {
      setSize();
    }, 100)
  })
}

const oneDay = 24 * 60 * 60 * 1000;

/**
 * 日期格式化
 * @param {date} String | new Date() | time 日期格式
 * @param {fmt} String 需要转换出的日期格式
 */
export function dateFormat(date, fmt = 'YYYY-MM-DD HH:mm:ss') {
  if (!date) {
    return ''
  }
  if (typeof date === 'string') {
    date = new Date(date.replace(/-/g, '/'))
  }
  if (typeof date === 'number') {
    date = new Date(date)
  }
  var o = {
    'M+': date.getMonth() + 1,
    'D+': date.getDate(),
    'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
    'H+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    'S': date.getMilliseconds()
  }
  var week = {
    '0': '\u65e5',
    '1': '\u4e00',
    '2': '\u4e8c',
    '3': '\u4e09',
    '4': '\u56db',
    '5': '\u4e94',
    '6': '\u516d'
  }
  if (/(Y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? '\u661f\u671f' : '\u5468') : '') + week[date.getDay() + ''])
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}

/**
 *
 * @param {source}  接受数字
 * @param {length} 逗号分隔长度
 */
export const numberComma = function (source, length = 3) {
  source = String(source).split(".");
  source[0] = source[0].replace(new RegExp('(\\d)(?=(\\d{' + length + '})+$)', 'ig'), "$1,");
  return source.join(".");
}


//获取浏览器滚动条宽度
let scrollBarWidth;

export const getScrollBarWidth = function () {
  if (Vue.prototype.$isServer) return 0;
  if (scrollBarWidth !== undefined) return scrollBarWidth;

  const outer = document.createElement('div');
  outer.className = 'z-scrollbar__wrap';
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  outer.style.position = 'absolute';
  outer.style.top = '-9999px';
  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  outer.style.overflow = 'scroll';

  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;
  outer.parentNode.removeChild(outer);
  scrollBarWidth = widthNoScroll - widthWithScroll;

  return scrollBarWidth;
};

/**
 * 转树结构
 * @param {Array} data - 原树结构一维数组
 * @param {Object} props - 标记树结构对应参数
 */
export function toTree(data, props = {
  id: 'id',
  pid: 'pid'
}) {
  let map = {};
  let result = [];
  //生成数据对象集合
  data.forEach(item => {
    map[item[props.id]] = item;
  })

  //生成结果集
  data.forEach(item => {
    const parent = map[item[props.pid]];
    if (parent) {
      if (!Array.isArray(parent.children)) parent.children = [];
      parent.children.push(item);
    } else {
      result.push(item);
    }
  })
  return result;
}

/**
 *
 * @param obj 需要监听的对象或数组
 * @param callback 当对应属性变化的时候触发的回调函数
 * @constructor
 */
export const watch = function (obj, lock = null, callback = empty) {
  this.callback = callback;
  //监听_obj对象 判断是否为对象,如果是数组,则对数组对应的原型进行封装
  //path代表相应属性在原始对象的位置,以数组表示. 如[ 'a', 'dd', 'ddd' ] 表示对象obj.a.dd.ddd的属性改变
  this.observe = function (_obj, path) {
    let type = Object.prototype.toString.call(_obj);
    if (type === '[object Object]' || type === '[object Array]' || type === '[object Window]') {
      this.observeObj(_obj, path);
      if (type == '[object Array]') {
        this.cloneArray(_obj, path);
      }
    }
  };

  //遍历对象obj,设置set,get属性,set属性能触发callback函数,并将val的值改为newVal
  //遍历结束后再次调用observe函数 判断val是否为对象,如果是则在对val进行遍历设置set,get
  this.observeObj = function (obj, path) {
    let t = this;
    for (let prop in obj) {
      if (!!lock && lock !== prop) continue;

      let val = obj[prop];
      let tpath = path.slice(0);
      tpath.push(prop);
      Object.defineProperty(obj, prop, {
        get: function () {
          return val;
        },
        set: function (newVal) {
          t.callback(tpath, newVal, val);
          val = newVal;
        }
      });
      t.observe(val, tpath);
    }
  };

  //通过对特定数组的原型中间放一个newProto原型,该原型继承于Array的原型,但是对push,pop等数组操作属性进行封装
  this.cloneArray = function (a_array, path) {
    let ORP = ['push', 'pop', 'shift', 'unshift', 'splice', 'sort', 'reverse'];
    let arrayProto = Array.prototype;
    let newProto = Object.create(arrayProto);
    let t = this;
    ORP.forEach(function (prop) {
      Object.defineProperty(newProto, prop, {
        value: function (newVal) {
          path.push(prop);
          t.callback(path, newVal);
          arrayProto[prop].apply(a_array, arguments);
        },
        enumerable: false,
        configurable: true,
        writable: true
      });
    });
    a_array.__proto__ = newProto;
  };

  //开始监听obj对象,初始path为[]
  this.observe(obj, []);
}

export const randomNumBoth = (Min, Max) => {
  let Range = Max - Min;
  let Rand = Math.random();
  let num = Min + Math.round(Rand * Range); //四舍五入

  return num;

}
