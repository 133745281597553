/* eslint-disable no-constant-condition */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import {
  getToken
} from './assets/js/auth'
import $ from 'jquery'
window.$ = $;
window.jQuery = $;

Vue.config.productionTip = false

/**
 * 本地css
 */
import './assets/scss/base.scss'
import './assets/scss/styles.scss'

/**
 * element-ui
 */
import ElementUI from 'element-ui'
import './assets/scss/element-variables.scss'
Vue.use(ElementUI, {
  size: 'medium'
})

console.log(process.env.VUE_APP_PRODUCT_PUBLISH_ENV,process.env)


/**
 * 图片查看器
 */
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)

/**
 * 富文本编辑器
 */
import VueQuillEditor from 'vue-quill-editor'
//一定要引入这三个css，不然文本编辑器会出现不规则黑白几何图形
//这三个css可以在main.js中引入，也可以在具体使用的.vue文件中引入
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
 
Vue.use(VueQuillEditor)

/*
 * axios请求封装
 */
import request from '@/assets/js/request.js'
Vue.use(request)

/**
 * 引入qs
 */
import qs from 'qs'      //引入qs
Vue.prototype.$qs = qs

/**
 * 框架 自定组件
 */
import '@/module'

router.beforeEach(async (to, from, next) => {
  const hasToken = getToken();
  // console.log(1);
  //替换页面title
  router.app.$post('/queryProjectName').then((res) => {
    document.title = res.content.projectName;
  })

  if (true || hasToken) {
    next();
    // console.log(2)
  } else {
    // console.log(3)
    if (to.path === '/Login') {
      next();
    }else if (to.path === '/OauthLogin') {
      next();
    } else {
      next({
        path: '/Login'
      });
    }
  }

})

/* eslint-disable no-new */
new Vue({
  router,
  render: h => h(App)
}).$mount("#app")
