<template>
  <div class="z-breadcrumb" v-if="levelList.length > level">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <span class="pull-left">当前位置：</span>

      <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
        <span
          class="no-redirect"
          v-if="item.redirect==='noRedirect'||index==levelList.length-1"
        >{{ item.meta.title }}</span>

        <span class="redirect" v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="pull-right">
      <slot v-bind:show="show" name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZBreadcrumb",

  data() {
    return {
      levelList: [],
      show: {}
    };
  },

  props: {
    exclude: Array,

    level: {
      type: Number,
      default: 0
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        // console.log(to)
        this.getBreadcrumb(to);
      }
    }
  },

  // mounted() {
  //   this.getBreadcrumb();
  // },

  methods: {
    getBreadcrumb(to) {
      let matched = to.matched.filter(
        item => item.meta && item.meta.title
      );
      const first = matched[0];

      this.levelList = matched.filter(
        item =>
          item.meta &&
          item.meta.title &&
          item.meta.breadcrumb !== false &&
          this.exclude.indexOf(item.path) === -1
      );
      this.show = !!first.meta.hasBtn;
    },

    handleLink(item) {
      const { redirect, name } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(name);
    }
  }
};
</script>

<style lang="scss" scoped>
.z-breadcrumb {
  padding: 20px 0;
  line-height: 36px;

  &::before {
    content: "";
    display: table;
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  .redirect {
    cursor: pointer;
    &:hover {
      color: $--color-primary;
    }
  }

  /deep/ .el-breadcrumb {
    display: inline-block;
  }
}
</style>