import Axios from 'axios';
import Qs from 'qs';

import {
  getToken
} from './auth'

const IS_MOBILE = false;
let vm;

Axios.interceptors.request.use(config => {
  if (config.headers['Content-Type'].indexOf('json') === -1) config.data = Qs.stringify(config.data);

  let TOKEN = IS_MOBILE ? getToken('app') : getToken();

  if (!!TOKEN) config.headers.Authorization = "Bearer " + TOKEN;

  return config;
}, error => {
  return Promise.reject(error);
});

Axios.interceptors.response.use(response => {
  return response;
}, error => {
  return Promise.reject(error);
});

Axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';

Axios.defaults.baseURL = './api';
Axios.defaults.timeout = 300000

function checkStatus(res) {
  if (res && (res.status === 200 || res.status === 304)) {
    return res.data;
  }

  return {
    status: -404,
    msg: '网络异常'
  };
}

function messageAlert(code, message) {
  vm.$notify.error({
    title: `错误 - ${code}`,
    message: message,
    duration: 0
  });
}

function checkCode(res) {
  // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户

  if (IS_MOBILE) {
    return res;
  }

  const _401 = res.toString().indexOf('401') != -1,
    _502 = res.toString().indexOf('502') != -1,
    _504 = res.toString().indexOf('504') != -1,
    _500 = res.toString().indexOf('500') != -1;

  if (_401) {
    messageAlert(401, '登录超时，请重新登录！');
    vm.$router.replace({
      path: '/Login'
    });
    return res;
  }

	if (_502) {
    messageAlert(502, '服务连接异常');
    return res;
  }

  if (_504) {
    messageAlert(504, '服务连接异常');
    return res;
  }

  if (_500) {
    messageAlert(500, '服务异常');
    return res;
  }

  vm.$notify.error({
    title: 'error',
    message: res,
    duration: 0
  });

  // if (res.status === -404) {
  //   alert(res.msg);
  // }
  // if (res.data && (!res.data.success)) {
  //   alert(res.data.error_msg);
  // }
  return res;
}

function handlerHeaders(requestHeader) {
  let headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest'
  }; //默认
  //
  if (requestHeader === 'binary') {
    headers = {
      'Content-Type': 'multipart/form-data'
    }; //指定传输数据为二进制类型，比如图片、mp3、文件。
  }

  if (requestHeader === 'json') {
    headers = {
      'Content-Type': 'application/json;charset=UTF-8'
    };
  }

  if (typeof requestHeader === 'object') {
    headers = requestHeader;
  }

  return headers;
}

export default {
  install(vue) {
    /**
     * post 请求
     * @param {string} url
     * @param {Object} params
     * @param {Number} requestHeader , 1 : 'Content-Type' : 'multipart/form-data'
     */
    vue.prototype.$post = function (url, params, requestHeader) {
      vm = this;

      return Axios.post(url, params || {}, {
        headers: handlerHeaders(requestHeader),
      }).then(
        (response) => {
          return checkStatus(response);
        }
      ).catch(
        (error) => {
          return checkCode(error);
        }
      );
    };

    vue.prototype.$get = function (url, params) {
      vm = this;
      return Axios.get(url, {
          params: params || {},
        })
        .then(
          (response) => {
            return checkStatus(response);
          }
        ).catch(
          (error) => {
            return checkCode(error);
          }
        );
    };

    /**
     * put 请求
     * @param {string} url
     * @param {Object} params
     * @param {Number} requestHeader
     */
    vue.prototype.$put = function (url, params, requestHeader) {
      vm = this;

      return Axios.put(url, params || {}, {
        headers: handlerHeaders(requestHeader),
      }).then(
        (response) => {
          return checkStatus(response);
        }
      ).catch(
        (error) => {
          return checkCode(error);
        }
      );
    };

    vue.prototype.$del = function (url, params) {
      vm = this;
      return Axios.delete(url, {
          params: params || {},
        })
        .then(
          (response) => {
            return checkStatus(response);
          }
        ).catch(
          (error) => {
            return checkCode(error);
          }
        );
    };

  }
};
