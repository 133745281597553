<template>
	<section class="no-data-index text-center" :class="{ 'is-mini': isMini }" ref="nodata-wrap" :style="{height: height}">

		<div class="no-data-inner" v-show="!loading">

			<div class="img-box">
				<img width="auto" height="auto" src="./img/3.png" alt="" class="img" />
			</div>

			<div class="right">
				<p class="context color_6 margin-reset th2" :class="{ 'margin-b15' : isShowBtn }">{{text}}</p>
				<div class="btn-box" v-if="isShowBtn">
					<el-button round size="small" v-show="isShowBack">返回首页</el-button>
					<el-button type="primary" round size="small" v-show="isShowRefresh">刷新页面</el-button>
				</div>
			</div>

		</div>

		<div class="is-loading" v-show="loading">
			<i class="el-icon-loading"></i>
			<span>数据加载中</span>
		</div>

	</section>
</template>

<script>

	export default {
		name: 'vNodata',
		data() {
			return {

      }
		},
		props: {
			text: {
				type: String,
				default: '暂无数据'
			},
			isShowBtn: {
				type: Boolean,
				default: false
			},
			isShowBack: {
				type: Boolean,
				default: true
			},
			isShowRefresh: {
				type: Boolean,
				default: true
			},
			isMini: {
				type: Boolean,
				default: false
			},
			height: String,
			loading: {
				type: Boolean,
				default: false
			}
		},
		mounted: function() {
			this.$nextTick(function() {
			})
		},
		methods: {
		}
	}
</script>

<style lang="scss" scoped>
.no-data-index {
	padding-top: 20px;
	padding-bottom: 20px;
	margin-bottom: 15px;
	height: 450px;
	&:after {
		display: inline-block;
		content: '';
		height: 100%;
		width: 0;
		vertical-align: middle;
	}

	.no-data-inner {
		display: inline-block;
		vertical-align: middle;
	}
	.img-box {
		max-width: 200px;
		max-height: 200px;
		margin: 0 auto;
		.img {
			max-height: 100%;
		}
	}
	&.is-mini {
		.img-box {
			padding: 0 20px;
		}
		.right * {
			font-size: 14px!important;
		}
	}

	.is-loading {
		line-height: 200px;
		text-align: center;
		color: #666;
	}
}
</style>