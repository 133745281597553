import Vue from 'vue';


// pc
import Nodata from './component/no_data/Index';
import Frame from './component/frame/Index';
import Pagination from './component/pagination/Index'; //element 分页封装
import Breadcrumb from './component/breadcrumb/Index'; //面包屑
import Plate from './component/plate'; //板块
// import {$err} from './common'
// import { on, off, addClass, removeClass, dateFormat } from './js/utils'

import './filters'

const components = [
  Nodata,
  Frame,
  Pagination,
  Breadcrumb,
  Plate
]

components.forEach(component => {
  Vue.component(component.name, component);
});
